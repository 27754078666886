// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Spinner from "../comman/Spinner";
// import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
// import ErrorAlert from "../comman/ErrorAlert";
// import SuccessAlert from "../comman/SuccessAlert";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };
// export default function Registration() {
//   const [referralCode, setReferralCode] = useState(null);
//   const query = useQuery();

//   useEffect(() => {
//     const referral = query.get("referral");
//     console.log(referral);
//     if (referral) {
//       setReferralCode(referral);
//     }
//   }, [query]);

//   const [showPass, setShowPass] = useState(false);
//   const { loading, error, message, auth } = useSelector((state) => state.auth);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const initialValues = {
//     username: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     referralBy: "",
//   };
//   const validationSchema = Yup.object().shape({
//     email: Yup.string().email("Incorrect email").required("Email is required"),
//     password: Yup.string().required("Password is required"),
//     confirmPassword: Yup.string().required("confirm password is required"),
//     username: Yup.string().required("username is required"),
//   });

//   const formik = useFormik({
//     initialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       if (referralCode) {
//         values.referralBy = referralCode;
//       }
//       dispatch(signupUser(values));
//     },
//   });

//   useEffect(() => {
//     if (error) {
//       const timer = setTimeout(() => {
//         dispatch(clearErrors());
//       }, 2000);

//       return () => clearTimeout(timer);
//     }
//     if (message) {
//       const timer = setTimeout(() => {
//         navigate("/");
//         dispatch(clearMessage());
//       }, 1000);

//       return () => clearTimeout(timer);
//     }
//   }, [error, dispatch, message, formik]);

//   return (
//     <>
//       <>
      
//         <div className="flex min-h-full flex-1 bg-black">
//           <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
//             <div className="mx-auto w-full max-w-sm lg:w-96">
//               <div>
//                 <img
//                   className="mx-auto h-10 w-auto"
//                   src="/Blackbot.png"
//                   alt="Your Company"
//                 />
//                 <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-300">
//                   Sign Up Here
//                 </h2>
//               </div>

//               <div className="mt-10">
//                 <div>
//                   <form className="space-y-2 " onSubmit={formik.handleSubmit}>
//                     <div className="md:flex gap-x-4 justify-between ">
//                       <div className="w-full">
//                         <label
//                           htmlFor="username"
//                           className="block text-sm font-medium leading-6 text-gray-300"
//                         >
//                           Username
//                         </label>
//                         <div className="mt-2">
//                           <input
//                             id="username"
//                             name="username"
//                             value={formik.values.username}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type="text"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                         </div>
//                         {formik.touched.username && formik.errors.username && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.username}*
//                           </p>
//                         )}
//                       </div>
//                       <div className="w-full">
//                         <label
//                           htmlFor="email"
//                           className="block text-sm font-medium leading-6 text-gray-300"
//                         >
//                           email
//                         </label>
//                         <div className="mt-2">
//                           <input
//                             id="email"
//                             name="email"
//                             value={formik.values.email}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type="email"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                         </div>
//                         {formik.touched.email && formik.errors.email && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.email}*
//                           </p>
//                         )}
//                       </div>
//                     </div>
//                     <div className="md:flex gap-x-4 justify-between ">
//                       <div className="w-full">
//                         <label
//                           htmlFor="password"
//                           className="block text-sm font-medium leading-6 text-gray-300"
//                         >
//                           Password
//                         </label>
//                         <div className="mt-2">
//                           <input
//                             id="password"
//                             name="password"
//                             value={formik.values.password}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type="password"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                         </div>
//                         {formik.touched.password && formik.errors.password && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.password}*
//                           </p>
//                         )}
//                       </div>
//                       <div className="w-full">
//                         <div className="flex items-center justify-between">
//                           <label
//                             htmlFor="confirmPassword"
//                             className="block text-sm font-medium leading-6 text-gray-300"
//                           >
//                             confirm Password
//                           </label>
//                         </div>
//                         <div className="mt-2">
//                           <input
//                             id="confirmPassword"
//                             name="confirmPassword"
//                             value={formik.values.confirmPassword}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type={showPass ? "text" : "password"}
//                             autoComplete="current-password"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                           <span
//                             onClick={() => setShowPass(!showPass)}
//                             className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600 cursor-pointer"
//                           >
//                             {/* {!showPass ? (
//                         <FaRegEye
//                           className="h-6 w-6 text-gray-600"
//                           aria-hidden="true"
//                         />
//                       ) : (
//                         <FaRegEyeSlash
//                           className="h-6 w-6 text-gray-600"
//                           aria-hidden="true"
//                         />
//                       )} */}
//                           </span>
//                         </div>
//                         {formik.touched.confirmPassword &&
//                           formik.errors.confirmPassword && (
//                             <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                               {formik.errors.confirmPassword}*
//                             </p>
//                           )}
//                       </div>
//                     </div>

//                     <div>
//                       <label
//                         htmlFor="referralBy"
//                         className="block text-sm font-medium leading-6 text-gray-300"
//                       >
//                         Referral Code (Optional)
//                       </label>
//                       <div className="mt-2">
//                         <input
//                           id="referralBy"
//                           name="referralBy"
//                           value={formik.values.referralBy}
//                           onChange={formik.handleChange}
//                           onBlur={formik.handleBlur}
//                           type="text"
//                           className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                         />
//                       </div>
//                       {formik.touched.referralBy &&
//                         formik.errors.referralBy && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.referralBy}*
//                           </p>
//                         )}
//                     </div>

//                     {error && <ErrorAlert error={error} />}
//                     {message && <SuccessAlert error={message} />}
//                     <div>
//                       <button
//                         type="submit"
//                         className={`flex w-full uppercase tracking-widest justify-center rounded mt-5 ${
//                           loading
//                             ? "bg-gray-200"
//                             : "bg-gray-800 hover:bg-indigo-600"
//                         } px-3 py-1.5 px-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
//                       >
//                         {loading ? <Spinner /> : "Registration"}
//                       </button>
//                     </div>
//                   </form>
//                   <p className="mt-4 text-center text-gray-300 text-sm ">
//                     Already a member?{" "}
//                     <Link
//                       to="/user/Login"
//                       className="font-semibold leading-6 text-gray-300"
//                     >
//                       Login Here
//                     </Link>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="relative hidden w-0 flex-1 lg:block">
//             <img
//               alt=""
//               src="./bot.png"
//               className="absolute inset-0 h-full w-full object-cover"
//             />
//           </div>
//         </div>
//       </>
//     </>
//   );
// }


// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Spinner from "../comman/Spinner";
// import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
// import ErrorAlert from "../comman/ErrorAlert";
// import SuccessAlert from "../comman/SuccessAlert";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

// export default function Registration() {
//   const [referralCode, setReferralCode] = useState(null);
//   const query = useQuery();

//   useEffect(() => {
//     const referral = query.get("referral");
//     if (referral) {
//       setReferralCode(referral);
//     }
//   }, [query]);

//   const [showPass, setShowPass] = useState(false);

//   const { loading, error, message } = useSelector((state) => state.auth);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const initialValues = {
//     username: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     referralBy: "",
//   };

//   const validationSchema = Yup.object().shape({
//     email: Yup.string().email("Incorrect email").required("Email is required"),
//     password: Yup.string()
//       .min(8, "Password must be at least 8 characters")
//       .required("Password is required"),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref('password'), null], 'Passwords must match')
//       .required("Confirm password is required"),
//     username: Yup.string().required("Username is required"),
//   });

//   const formik = useFormik({
//     initialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       if (referralCode) {
//         values.referralBy = referralCode;
//       }
//       dispatch(signupUser(values));
//     },
//   });

//   useEffect(() => {
//     if (error) {
//       const timer = setTimeout(() => {
//         dispatch(clearErrors());
//       }, 2000);
//       return () => clearTimeout(timer);
//     }
//     if (message) {
//       const timer = setTimeout(() => {
//         navigate("/");
//         dispatch(clearMessage());
//       }, 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [error, dispatch, message, navigate]);

//   return (
//     <>
//       <div
//         className="relative min-h-screen flex items-center justify-end bg-cover bg-center"
//         style={{ backgroundImage: `url('/bul2.jpg')` }}
//       >
//         {/* Overlay for darkening the background */}
//         <div className="absolute inset-0 bg-black opacity-50"></div>

//         {/* Form container aligned to the right */}
//         <div className="relative bg-white bg-opacity-20 backdrop-blur-lg shadow-lg rounded-lg p-8 max-w-lg w-full">
//           <div className="text-left">
//             <Link to="/">
//               <img className="h-10 w-auto mb-4" src="/bfc.png" alt="Company Logo" />
//             </Link>
//             <h2 className="text-3xl font-bold text-white">Create your account</h2>
//             <p className="text-gray-200 mb-6">Start your journey with us today</p>
//           </div>

//           <form className="space-y-6" onSubmit={formik.handleSubmit}>
//             <div className="flex gap-4">
//               {/* Username */}
//               <div className="w-full">
//                 <label htmlFor="username" className="block text-sm font-medium text-white">Username</label>
//                 <input
//                   id="username"
//                   name="username"
//                   type="text"
//                   required
//                   value={formik.values.username}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                 />
//                 {formik.touched.username && formik.errors.username && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.username}</p>
//                 )}
//               </div>

//               {/* Email */}
//               <div className="w-full">
//                 <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
//                 <input
//                   id="email"
//                   name="email"
//                   type="email"
//                   required
//                   value={formik.values.email}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                 />
//                 {formik.touched.email && formik.errors.email && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.email}</p>
//                 )}
//               </div>
//             </div>

//             <div className="flex gap-4">
//               {/* Password */}
//               <div className="w-full">
//                 <label htmlFor="password" className="block text-sm font-medium text-white">Password</label>
//                 <div className="relative mt-2">
//                   <input
//                     id="password"
//                     name="password"
//                     type={showPass ? "text" : "password"}
//                     required
//                     value={formik.values.password}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   />
//                   <span
//                     onClick={() => setShowPass(!showPass)}
//                     className="absolute right-4 top-3 cursor-pointer text-gray-300"
//                   >
//                     {showPass ? <FaRegEyeSlash /> : <FaRegEye />}
//                   </span>
//                 </div>
//                 {formik.touched.password && formik.errors.password && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.password}</p>
//                 )}
//               </div>

//               {/* Confirm Password */}
//               <div className="w-full">
//                 <label htmlFor="confirmPassword" className="block text-sm font-medium text-white">Confirm Password</label>
//                 <div className="relative mt-2">
//                   <input
//                     id="confirmPassword"
//                     name="confirmPassword"
//                     type={showPass ? "text" : "password"}
//                     required
//                     value={formik.values.confirmPassword}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   />
//                 </div>
//                 {formik.touched.confirmPassword && formik.errors.confirmPassword && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.confirmPassword}</p>
//                 )}
//               </div>
//             </div>

//             {/* Referral Code */}
//             <div>
//               <label htmlFor="referralBy" className="block text-sm font-medium text-white">Referral Code</label>
//               <input
//                 id="referralBy"
//                 name="referralBy"
//                 type="text"
//                 value={referralCode || formik.values.referralBy}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                 disabled={!!referralCode}
//               />
//             </div>

//             {/* Submit Button */}
//             <button
//               type="submit"
//               className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
//               disabled={loading}
//             >
//               {loading ? <Spinner /> : "Sign Up"}
//             </button>
//           </form>

//           <p className="mt-6 text-left text-gray-300">
//             Already a member?{" "}
//             <Link to="/" className="text-blue-400 hover:text-blue-300">
//               Login Here
//             </Link>
//           </p>
//         </div>
//       </div>

//       {/* Error and Success Alerts */}
//       {error && <ErrorAlert error={error} />}
//       {message && <SuccessAlert message={message} />}
//     </>
//   );
// }


// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Spinner from "../comman/Spinner";
// import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
// import ErrorAlert from "../comman/ErrorAlert";
// import SuccessAlert from "../comman/SuccessAlert";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

// export default function Registration() {
//   const [referralCode, setReferralCode] = useState(null);
//   const query = useQuery();

//   useEffect(() => {
//     const referral = query.get("referral");
//     if (referral) {
//       setReferralCode(referral);
//     }
//   }, [query]);

//   const [showPass, setShowPass] = useState(false);

//   const { loading, error, message } = useSelector((state) => state.auth);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const initialValues = {
//     username: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     referralBy: "",
//   };

//   const validationSchema = Yup.object().shape({
//     email: Yup.string().email("Incorrect email").required("Email is required"),
//     password: Yup.string()
//       .min(8, "Password must be at least 8 characters")
//       .required("Password is required"),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref('password'), null], 'Passwords must match')
//       .required("Confirm password is required"),
//     username: Yup.string().required("Username is required"),
//   });

//   const formik = useFormik({
//     initialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       if (referralCode) {
//         values.referralBy = referralCode;
//       }
//       dispatch(signupUser(values));
//     },
//   });

//   useEffect(() => {
//     if (error) {
//       const timer = setTimeout(() => {
//         dispatch(clearErrors());
//       }, 2000);
//       return () => clearTimeout(timer);
//     }
//     if (message) {
//       const timer = setTimeout(() => {
//         navigate("/");
//         dispatch(clearMessage());
//       }, 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [error, dispatch, message, navigate]);

//   return (
//     <>
//       <div
//         className="relative min-h-screen flex items-center justify-end bg-cover bg-center"
//         style={{ backgroundImage: `url('/bul2.jpg')` }}
//       >
//         {/* Overlay for darkening the background */}
//         <div className="absolute inset-0 bg-black opacity-50"></div>

//         {/* Form container aligned to the right with right padding */}
//         <div className="relative bg-white bg-opacity-20 backdrop-blur-lg shadow-lg rounded-lg p-8 max-w-lg w-full mr-8">
//           <div className="text-left">
//             <Link to="/">
//               <img className="h-10 w-auto mb-4" src="/bfc.png" alt="Company Logo" />
//             </Link>
//             <h2 className="text-3xl font-bold text-white">Create your account</h2>
//             <p className="text-gray-200 mb-6">Start your journey with us today</p>
//           </div>

//           <form className="space-y-6" onSubmit={formik.handleSubmit}>
//             <div className="flex gap-4">
//               {/* Username */}
//               <div className="w-full">
//                 <label htmlFor="username" className="block text-sm font-medium text-white">Username</label>
//                 <input
//                   id="username"
//                   name="username"
//                   type="text"
//                   required
//                   value={formik.values.username}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                 />
//                 {formik.touched.username && formik.errors.username && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.username}</p>
//                 )}
//               </div>

//               {/* Email */}
//               <div className="w-full">
//                 <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
//                 <input
//                   id="email"
//                   name="email"
//                   type="email"
//                   required
//                   value={formik.values.email}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                 />
//                 {formik.touched.email && formik.errors.email && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.email}</p>
//                 )}
//               </div>
//             </div>

//             <div className="flex gap-4">
//               {/* Password */}
//               <div className="w-full">
//                 <label htmlFor="password" className="block text-sm font-medium text-white">Password</label>
//                 <div className="relative mt-2">
//                   <input
//                     id="password"
//                     name="password"
//                     type={showPass ? "text" : "password"}
//                     required
//                     value={formik.values.password}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   />
//                   <span
//                     onClick={() => setShowPass(!showPass)}
//                     className="absolute right-4 top-3 cursor-pointer text-gray-300"
//                   >
//                     {showPass ? <FaRegEyeSlash /> : <FaRegEye />}
//                   </span>
//                 </div>
//                 {formik.touched.password && formik.errors.password && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.password}</p>
//                 )}
//               </div>

//               {/* Confirm Password */}
//               <div className="w-full">
//                 <label htmlFor="confirmPassword" className="block text-sm font-medium text-white">Confirm Password</label>
//                 <div className="relative mt-2">
//                   <input
//                     id="confirmPassword"
//                     name="confirmPassword"
//                     type={showPass ? "text" : "password"}
//                     required
//                     value={formik.values.confirmPassword}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   />
//                 </div>
//                 {formik.touched.confirmPassword && formik.errors.confirmPassword && (
//                   <p className="text-red-500 text-xs mt-2">{formik.errors.confirmPassword}</p>
//                 )}
//               </div>
//             </div>

//             {/* Referral Code */}
//             <div>
//               <label htmlFor="referralBy" className="block text-sm font-medium text-white">Referral Code</label>
//               <input
//                 id="referralBy"
//                 name="referralBy"
//                 type="text"
//                 value={referralCode || formik.values.referralBy}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                 disabled={!!referralCode}
//               />
//             </div>

//             {/* Submit Button */}
//             <button
//               type="submit"
//               className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
//               disabled={loading}
//             >
//               {loading ? <Spinner /> : "Sign Up"}
//             </button>
//           </form>

//           <p className="mt-6 text-left text-gray-300">
//             Already a member?{" "}
//             <Link to="/" className="text-blue-400 hover:text-blue-300">
//               Login Here
//             </Link>
//           </p>
//         </div>
//       </div>

//       {/* Error and Success Alerts */}
//       {error && <ErrorAlert error={error} />}
//       {message && <SuccessAlert message={message} />}
//     </>
//   );
// }




// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Spinner from "../comman/Spinner";
// import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
// import ErrorAlert from "../comman/ErrorAlert";
// import SuccessAlert from "../comman/SuccessAlert";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";

// // Custom hook to get query params
// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

// export default function Registration() {
//   const [referralCode, setReferralCode] = useState(null);
//   const query = useQuery();

//   useEffect(() => {
//     const referral = query.get("referral");
//     if (referral) {
//       setReferralCode(referral);
//     }
//   }, [query]);

//   const [showPass, setShowPass] = useState(false);

//   const { loading, error, message } = useSelector((state) => state.auth);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const initialValues = {
//     username: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     referralBy: "",
//   };

//   const validationSchema = Yup.object().shape({
//     email: Yup.string().email("Incorrect email").required("Email is required"),
//     password: Yup.string()
//       .min(8, "Password must be at least 8 characters")
//       .required("Password is required"),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref('password'), null], 'Passwords must match')
//       .required("Confirm password is required"),
//     username: Yup.string().required("Username is required"),
//   });

//   const formik = useFormik({
//     initialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       if (referralCode) {
//         values.referralBy = referralCode;
//       }
//       dispatch(signupUser(values));
//     },
//   });

//   useEffect(() => {
//     if (error) {
//       const timer = setTimeout(() => {
//         dispatch(clearErrors());
//       }, 2000);
//       return () => clearTimeout(timer);
//     }
//     if (message) {
//       const timer = setTimeout(() => {
//         navigate("/");
//         dispatch(clearMessage());
//       }, 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [error, dispatch, message, navigate]);

//   return (
//     <>
//       <div className="min-h-screen flex items-center justify-center px-4">
//         {/* Container for the image and form */}
//         <div className="flex flex-col lg:flex-row w-full max-w-6xl shadow-lg rounded-lg overflow-hidden">
//           {/* Left side: Image */}
//           <div className="lg:w-1/2 w-full h-64 lg:h-auto bg-cover bg-center" style={{ backgroundImage: `url(./payreg.png)` }}>
//           </div>

//           {/* Right side: Form */}
//           <div className="lg:w-1/2 w-full bg-white bg-opacity-20 backdrop-blur-lg shadow-lg rounded-lg p-8">
//             <div className="text-left">
//               <Link to="/">
//                 <img className="h-10 w-auto mb-4" src="/bfc.png" alt="Company Logo" />
//               </Link>
//               <h2 className="text-3xl font-bold text-black">Create your account</h2>
//               <p className="text-gray-700 mb-6">Start your journey with us today</p>
//             </div>

//             <form className="space-y-6" onSubmit={formik.handleSubmit}>
//               <div className="flex flex-col lg:flex-row gap-4">
//                 {/* Username */}
//                 <div className="w-full">
//                   <label htmlFor="username" className="block text-sm font-medium text-black">Username</label>
//                   <input
//                     id="username"
//                     name="username"
//                     type="text"
//                     required
//                     value={formik.values.username}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   />
//                   {formik.touched.username && formik.errors.username && (
//                     <p className="text-red-500 text-xs mt-2">{formik.errors.username}</p>
//                   )}
//                 </div>

//                 {/* Email */}
//                 <div className="w-full">
//                   <label htmlFor="email" className="block text-sm font-medium text-black">Email</label>
//                   <input
//                     id="email"
//                     name="email"
//                     type="email"
//                     required
//                     value={formik.values.email}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   />
//                   {formik.touched.email && formik.errors.email && (
//                     <p className="text-red-500 text-xs mt-2">{formik.errors.email}</p>
//                   )}
//                 </div>
//               </div>

//               <div className="flex flex-col lg:flex-row gap-4">
//                 {/* Password */}
//                 <div className="w-full">
//                   <label htmlFor="password" className="block text-sm font-medium text-black">Password</label>
//                   <div className="relative mt-2">
//                     <input
//                       id="password"
//                       name="password"
//                       type={showPass ? "text" : "password"}
//                       required
//                       value={formik.values.password}
//                       onChange={formik.handleChange}
//                       onBlur={formik.handleBlur}
//                       className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                     />
//                     <span
//                       onClick={() => setShowPass(!showPass)}
//                       className="absolute right-4 top-3 cursor-pointer text-gray-300"
//                     >
//                       {showPass ? <FaRegEyeSlash /> : <FaRegEye />}
//                     </span>
//                   </div>
//                   {formik.touched.password && formik.errors.password && (
//                     <p className="text-red-500 text-xs mt-2">{formik.errors.password}</p>
//                   )}
//                 </div>

//                 {/* Confirm Password */}
//                 <div className="w-full">
//                   <label htmlFor="confirmPassword" className="block text-sm font-medium text-black">Confirm Password</label>
//                   <div className="relative mt-2">
//                     <input
//                       id="confirmPassword"
//                       name="confirmPassword"
//                       type={showPass ? "text" : "password"}
//                       required
//                       value={formik.values.confirmPassword}
//                       onChange={formik.handleChange}
//                       onBlur={formik.handleBlur}
//                       className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                     />
//                   </div>
//                   {formik.touched.confirmPassword && formik.errors.confirmPassword && (
//                     <p className="text-red-500 text-xs mt-2">{formik.errors.confirmPassword}</p>
//                   )}
//                 </div>
//               </div>

//               {/* Referral Code */}
//               <div>
//                 <label htmlFor="referralBy" className="block text-sm font-medium text-black">Referral Code</label>
//                 <input
//                   id="referralBy"
//                   name="referralBy"
//                   type="text"
//                   value={referralCode || formik.values.referralBy}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
//                   disabled={!!referralCode}
//                 />
//               </div>

//               {/* Submit Button */}
//               <button
//                 type="submit"
//                 className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
//                 disabled={loading}
//               >
//                 {loading ? <Spinner /> : "Sign Up"}
//               </button>
//             </form>

//             <p className="mt-6 text-left text-gray-300">
//               Already a member?{" "}
//               <Link to="/" className="text-blue-400 hover:text-blue-300">
//                 Login Here
//               </Link>
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* Error and Success Alerts */}
//       {error && <ErrorAlert error={error} />}
//       {message && <SuccessAlert message={message} />}
//     </>
//   );
// }


import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../comman/Spinner";
import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
import ErrorAlert from "../comman/ErrorAlert";
import SuccessAlert from "../comman/SuccessAlert";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Custom hook to get query params
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Registration() {
  const [referralCode, setReferralCode] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const referral = query.get("referral");
    if (referral) {
      setReferralCode(referral);
    }
  }, [query]);

  const [showPass, setShowPass] = useState(false);

  const { loading, error, message } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralBy: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Incorrect email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    username: Yup.string().required("Username is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (referralCode) {
        values.referralBy = referralCode;
      }
      dispatch(signupUser(values));
    },
  });

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (message) {
      const timer = setTimeout(() => {
        navigate("/");
        dispatch(clearMessage());
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [error, dispatch, message, navigate]);

  return (
    <>
      <div
        className="min-h-screen flex items-center justify-center px-4 bg-gradient-to-r from-black via-gray-800 to-yellow-500 bg-cover bg-center"
        style={{ backgroundImage: `url(./money.jpg)` }}
      >
        {/* Container for the image and form */}
        <div className="flex flex-col lg:flex-row w-full max-w-6xl shadow-lg rounded-lg overflow-hidden">
          {/* Left side: Empty for now, or you can place an image */}
          <div className="hidden lg:block lg:w-1/2 bg-cover bg-center" style={{ backgroundImage: `url(./your-left-image.jpg)` }}></div>

          {/* Right side: Form */}
          <div className="lg:w-1/2 w-full bg-white bg-opacity-20 backdrop-blur-lg shadow-lg rounded-lg p-8">
            <div className="text-left">
              <Link to="/">
                <img className="h-10 w-auto mb-4" src="/bitpay.png" alt="Company Logo" />
              </Link>
              <h2 className="text-3xl font-bold text-white">Create your account</h2>
              <p className="text-gray-200 mb-6">Start your journey with us today</p>
            </div>

            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div className="flex flex-col lg:flex-row gap-4">
                {/* Username */}
                <div className="w-full">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-100">
                    Username
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                  />
                  {formik.touched.username && formik.errors.username && (
                    <p className="text-red-500 text-xs mt-2">{formik.errors.username}</p>
                  )}
                </div>

                {/* Email */}
                <div className="w-full">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-100">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-red-500 text-xs mt-2">{formik.errors.email}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-4">
                {/* Password */}
                <div className="w-full">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-100">
                    Password
                  </label>
                  <div className="relative mt-2">
                    <input
                      id="password"
                      name="password"
                      type={showPass ? "text" : "password"}
                      required
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                    />
                    <span
                      onClick={() => setShowPass(!showPass)}
                      className="absolute right-4 top-3 cursor-pointer text-gray-300"
                    >
                      {showPass ? <FaRegEyeSlash /> : <FaRegEye />}
                    </span>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <p className="text-red-500 text-xs mt-2">{formik.errors.password}</p>
                  )}
                </div>

                {/* Confirm Password */}
                <div className="w-full">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-100">
                    Confirm Password
                  </label>
                  <div className="relative mt-2">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showPass ? "text" : "password"}
                      required
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <p className="text-red-500 text-xs mt-2">{formik.errors.confirmPassword}</p>
                  )}
                </div>
              </div>

              {/* Referral Code */}
              <div>
                <label htmlFor="referralBy" className="block text-sm font-medium text-gray-100">
                  Referral Code
                </label>
                <input
                  id="referralBy"
                  name="referralBy"
                  type="text"
                  value={referralCode || formik.values.referralBy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="mt-2 block w-full bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                  disabled={!!referralCode}
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                disabled={loading}
              >
                {loading ? <Spinner /> : "Sign Up"}
              </button>
            </form>

            <p className="mt-6 text-left text-gray-300">
              Already a member?{" "}
              <Link to="/" className="text-blue-400 hover:text-blue-300">
                Login Here
              </Link>
            </p>
          </div>
        </div>
      </div>

      {/* Error and Success Alerts */}
      {error && <ErrorAlert error={error} />}
      {message && <SuccessAlert message={message} />}
    </>
  );
}
